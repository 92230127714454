















































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import hexabaseState from '@/store/hexabase';
import Setting from '@/models/settingModel';
import { ActionList, FieldChildrenProp } from '@/services/hexabase/types';
import { employeeMasterForm, EmployeeRules } from '@/constants/form/settingEmployeeForm';
import { EmployeeDetailDefine } from '@/constants/setting/employee';
import { MemberList } from '@/constants/setting/types';

import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import EditAction from '@/components/setting/detail/EditAction.vue';
import InputPanel from '@/components/setting/detail/InputPanel.vue';
import ExecAction from '@/components/common/modules/ExecAction.vue';
import TransitionDialog from '@/components/common/modules/TransitionDialog.vue';

import AutoCreateForm from '@/components/form/AutoCreateForm.vue';
import SingleText from '@/components/common/elements/SingleText.vue';

Component.registerHooks(['beforeRouteEnter']);
@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    EditAction,
    InputPanel,
    ExecAction,
    TransitionDialog,
    AutoCreateForm,
    SingleText
  },
  beforeRouteEnter(to: Route, from: Route, next: any): void {
    next((vm: any) => {
      vm.prevRoute = from;
      next();
    });
  }
})
export default class SettingEmployeeRegister extends Vue {
  // model
  private setting = new Setting();
  // アプリケーションID
  private applicationId = hexabaseState.applicationId;
  // 社員マスタのデータストアのID
  private employeeMasterDsId = hexabaseState.datastoreIds['社員マスタ'];
  // マスタデータ
  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
  // 社員基本情報のデータ
  private fieldsData: FieldChildrenProp[] = [];
  // 社員基本情報のカラムデータ
  private fieldCols = '';
  // 社員のデータ
  private membersData: MemberList[] = [];
  // 登録用データ
  private registDataPayload: any = { item: {} };
  // ダイヤログ用データ
  private dialogStatus = false;
  private bodyText = '';
  private button = [
    {
      id: 1,
      color: 'primary',
      type: 'routing',
      name: 'routing',
      value: '詳細画面へ',
      route: `/setting/employee/edit/:id`
    }
  ];

  // コンポーネントで使用するタイトル等
  private execActionMessage = EmployeeDetailDefine.regist.message.execAction;
  private dialogTitle = EmployeeDetailDefine.regist.dialogTitle;
  // キャンセルボタン押下時の遷移時に使用
  private prevRoute: any = null;
  // 画面下部に表示する利用可能な新規登録アクション
  availableNewAction: ActionList[] = [];

  async mounted() {
    this.initial();
  }

  public async initial() {
    try {
      this.$store.commit('hexabase/setIsLoading', true);
      await this.setting.loadMasterData();
      this.masterData = this.setting.getMaster();

      const [membersData, newAction] = await Promise.all([
        this.setting.getDbMembers(),
        this.setting.getNewActionList(this.employeeMasterDsId)
      ]);

      const initialAction = newAction.actions.filter(
        action => action.display_id === EmployeeDetailDefine.regist.registActionName
      );
      this.availableNewAction = initialAction;

      const inputFields = await this.setting.getFieldsByActionId(
        this.employeeMasterDsId,
        initialAction[0].action_id
      );

      let fields = await this.setting.getField(
        this.applicationId,
        this.employeeMasterDsId,
        this.masterData,
        employeeMasterForm,
        undefined,
        inputFields
      );

      // 入力ルール付与
      fields = this.setting._addInputRules(
        fields,
        inputFields.action_field_settings,
        EmployeeRules,
        this.masterData.m_employee.body
      );
      fields = this.setting.modifyInputAllow(fields, inputFields.action_field_settings);

      this.fieldsData.push(...this.setting.setFieldData(fields, membersData));
      this.fieldCols = this.setting.getGridStatus(fields);
      this.membersData.push(...membersData);
    } catch (e) {
      console.log(e);
      this.setError(e as string | object);
    } finally {
      this.$store.commit('hexabase/setIsLoading', false);
    }
  }

  /**
   * ヘッダ情報フィールド定義作成
   */
  private getHeaderFields(formData: any) {
    const baseData = {
      cols: {},
      datas: {} as { [k: string]: any }
    };
    try {
      baseData.cols = this.setting.getGridStatus(formData);
      baseData.datas = formData;
    } catch (e) {
      this.setError(e as string | object);
    }
    return baseData;
  }

  /**
   * ヘッダ情報フィールドデータ作成
   */
  private getHeaderData(setData: { [key: string]: any }, baseData: any) {
    try {
      Object.keys(setData).map((key: string) => {
        baseData.datas[key].props['value'] = setData[key];
      });
    } catch (e) {
      this.setError(e as string | object);
    }
  }

  /**
   * 入力されているメールアドレスから
   * HexaBase登録ユーザーのアドレスを割り出します
   */
  suggest(): void {
    const result = this.setting.detailPageSuggest(this.fieldsData, this.membersData);
    if (result !== null) {
      this.$set(this.fieldsData[result.index].props!, 'value', result.value);
    }
  }

  /**
   * 自動生成要素からの入力を取得(社員マスタ登録情報パネル側)
   * @param input {[k:string]: string}
   * @returns void
   */
  private catchInput(input: { [k: string]: string }): void {
    this.registDataPayload.item[input.name] = input.value;

    const index = this.fieldsData.findIndex(v => v.display_id === input.name);
    switch (this.fieldsData[index].component) {
      case 'MasterSelectControl':
      case 'MasterSelectSetControl':
      case 'MasterSelectMultiControl':
      case 'MasterSelectMultiSetControl': {
        this.$set(this.fieldsData[index].props!, 'value', {
          i_id: input.value,
          item_id: input.value
        });
        break;
      }
      default: {
        this.$set(this.fieldsData[index].props!, 'value', input.value);
        break;
      }
    }
  }

  /**
   * アイテム情報の更新・キャンセルを実行します
   * @param type - 押下されたボタンの種類
   * @returns void
   */
  private async execAction(type: string): Promise<void> {
    switch (type) {
      case 'register': {
        try {
          this.$store.commit('hexabase/setIsLoading', true);
          // 登録するのアクションIDを指定
          this.registDataPayload['action_id'] = 'register';

          const result = await this.setting.registerLinkedItems(
            this.applicationId,
            this.employeeMasterDsId,
            this.registDataPayload
          );
          this.setting.apiErrorHandle('CREATEITEM', result);
          // APIレスポンスのitem_idを社員マスタ詳細画面への遷移先に設定
          this.button.map(v => {
            v.route = String(v.route).replace(':id', result.item_id);
          });
          this.setDialogMessage(EmployeeDetailDefine.regist.message.registSuccess);
          this.reset();
        } catch (e) {
          this.setDialogMessage(e as string);
          this.reset();
        } finally {
          this.setDialogStatus(true);
          this.$store.commit('hexabase/setIsLoading', false);
        }
        break;
      }
      case 'cancel': {
        this.backPreviousPage();
        break;
      }
      default: {
        break;
      }
    }
  }

  private setDialogStatus(flg: boolean) {
    this.dialogStatus = flg;
  }
  private setDialogMessage(str: string) {
    this.bodyText = str;
  }

  private reset() {
    this.registDataPayload = { item: {} };
  }

  private setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.setting.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }

  /**
   * 戻る処理
   */
  private backPreviousPage() {
    if (this.prevRoute) {
      const beforeRoute = { name: this.prevRoute.name, params: this.prevRoute.params, query: {} };
      const paths = this.prevRoute.fullPath.split('/');
      if (['list', 'searchlist'].includes(paths[paths.length - 1].toLowerCase())) {
        beforeRoute.query = { searchConditions: 'true' };
      }

      if (this.prevRoute.name !== null) {
        this.$router.push(beforeRoute);
      } else {
        this.$router.replace({ name: 'SettingEmployeeList', params: {} });
      }
    } else {
      this.$router.replace({ name: 'SettingEmployeeList', params: {} });
    }
  }
}
